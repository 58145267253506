import 'intersection-observer';

import React, { Component } from 'react';
import { Row, Col, Carousel, Container, Card } from 'react-bootstrap';
// import ScrollUpButton from "react-scroll-up-button";
import { TimelineLite } from 'gsap';
import Observer from 'react-intersection-observer';
import { Link, graphql } from 'gatsby';
import Img from 'gatsby-image';
import LightBluekey from '../../static/assets/light_blue_key.svg';
import YellowKey from '../../static/assets/yellow_key.svg';
import YellowKeySecond from '../../static/assets/yellow_key_second.svg';
import ReadMore from '../../static/assets/whiteArrow.svg';
import RightArrow from '../../static/img/home-page/Right_arrow.svg';
import LeftArrow from '../../static/img/home-page/Left_arrow.svg';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import downArrow from '../../static/img/home-page/down_arrow.svg';

import '../sass/_index.scss';
import * as globalAnimation from '../animations/globalAnimation';

export default class IndexPage extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isTop: true,
      index: 0,
      direction: null,
      carouselItemCount: 8,
    };
    this.tl = new TimelineLite({ force3D: !0, yoyo: true });
  }

  componentDidMount() {
    const tl = new TimelineLite({ force3D: !0, yoyo: true });
    document.addEventListener('scroll', () => {
      const isTop = window.scrollY < 450;
      if (isTop !== this.state.isTop) {
        this.setState({ isTop });
      }
    });
    globalAnimation.fadeUp(this.tl, '.animate-fade-up');
    globalAnimation.opacityZero(tl, '.op0');
  }

  animateCulture = InView => {
    const tlCulture = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeUp(tlCulture, '.animate-culture-fade-up');
      globalAnimation.fadeRight(tlCulture, '.animate-culture-fade-right');
      globalAnimation.fadeDown(tlCulture, '.animate-culture-fade-down');
      globalAnimation.fadeLeft(tlCulture, '.animate-culture-fade-left');
      globalAnimation.animateImageXLeft(
        tlCulture,
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue'
      );
    }
  };

  animateInnovationCard = InView => {
    const tlInnovationCard = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      globalAnimation.fadeLeft(tlInnovationCard, '.animate-innovation-card-left');
      globalAnimation.fadeRight(tlInnovationCard, '.animate-innovation-card-right');
    }
  };

  animateOurImpact = InView => {
    const tlOurImpact = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      // globalAnimation.fadeUpHome(tlOurImpact, '.animate-impact-fade-up');
      // globalAnimation.animateImageXLeft(
      //   tlOurImpact,
      //   '.svg-line__line_yellow',
      //   '.svg_circle_yellow'
      // );
      globalAnimation.fadeLeft(tlOurImpact, '.animate-impact-fade-left');
      globalAnimation.fadeRight(tlOurImpact, '.animate-impact-fade-right');
      globalAnimation.animateWhoWeAreSection(
        tlOurImpact,
        '.animate-impact-fade-up',
        '.svg-line__line_yellow',
        '.svg_circle_yellow',
        '.animate-impact-fade-desc-up'
      );
    }
  };

  animateDifferent = InView => {
    const tlDiff = new TimelineLite({ force3D: !0, yoyo: true });
    // const tl = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      // globalAnimation.fadeUpHome(tlDiff, '.animate-diff-fade-up');
      globalAnimation.fadeDown(tlDiff, '.animate-diff-fade-down');

      // globalAnimation.animateImageXLeft(
      //   tlDiff,
      //   '.svg-line__line_yellow_second',
      //   '.svg_circle_yellow_second'
      // );
      globalAnimation.animateWhoWeAreDiff(
        tlDiff,
        '.animate-diff-fade-up',
        '.svg-line__line_yellow_second',
        '.svg_circle_yellow_second',
        '.animate-diff-fade-left',
        '.animate-diff-fade-desc-up'
      );
      // globalAnimation.fadeLeft(tlDiff, '.animate-diff-fade-left');
    }
  };

  animateWhoWeAre = InView => {
    const tlWhoWeAre = new TimelineLite({ force3D: !0, yoyo: true });
    if (InView) {
      // globalAnimation.fadeUpHome(tlWhoWeAre, '.animate-who-we-up');
      // globalAnimation.animateImageXLeft(
      //   tlWhoWeAre,
      //   '.svg-line__line_lightblue',
      //   '.svg_circle_lightblue'
      // );
      // globalAnimation.fadeUpHome(tl, '.animate-who-we-desc-up');
      globalAnimation.fadeRight(tlWhoWeAre, '.animate-who-we-right');
      globalAnimation.animateWhoWeAreSection(
        tlWhoWeAre,
        '.animate-who-we-up',
        '.svg-line__line_lightblue',
        '.svg_circle_lightblue',
        '.animate-who-we-desc-up'
      );
    }
  };

  toggleCarousel = direction => {
    let { index } = this.state;
    const [min, max] = [0, this.state.carouselItemCount - 1];

    if (direction === 'next') {
      index += 1;
    } else if (direction === 'prev') {
      index -= 1;
    }

    if (index > max) {
      // at max, start from top
      index = 0;
    }

    if (index < min) {
      // at min, start from max
      index = max;
    }
    this.setState({
      direction,
      index,
    });
  };

  render() {
    const { index, direction, carouselItemCount, isTop } = this.state;
    const {
      data: {
        markdownRemark: {
          frontmatter: {
            carousel,
            sectionOne,
            sectionTwo,
            sectionThree,
            sectionFour,
            sectionFive: { one, two, three },
          },
        },
      },
    } = this.props;
    return (
      <Layout navbarColor={`${isTop ? 'transperent' : 'appcolor'}`}>
        <SEO pageTitle="Home" />
        {/* <ScrollUpButton /> */}
        <Carousel controls={false} pauseOnHover={false} autoPlay={false} className="main_carousel">
          {carousel.map(slider => (
            <Carousel.Item>
              <Row className={`vh-100 carousel-img ${slider.class}`}>
                <Col lg={`${slider.columnClass}`}>
                  <div
                    className={`d-flex slider-text ${slider.positionClass} flex-column h-100 align-items-end wrapper py-0`}
                  >
                    <div className={`${slider.spacingClass} pl-lg-5 w-100 mt-5`}>
                      <h3 className="text-bold text-white mb-lg-3">{slider.title}</h3>

                      <p className="text-white font-weight-light d-none d-lg-block">
                        {slider.description}
                      </p>
                      {slider.buttonText && slider.buttonLink && (
                        <div className="d-flex justify-content-md-start mt-4 mt-md-1">
                          <Link
                            to={slider.buttonLink}
                            id="desc"
                            className="py-2 px-3 shadow text-white text-center bg-appcolor-light employee-card-body"
                          >
                            <div className="d-flex justify-content-center">
                              <span className="text-white font-weight-light my-0 mx-3 text-uppercase">
                                {slider.buttonText}
                                <ReadMore className="arrow-translate" height="12px" width="40px" />
                              </span>
                            </div>
                          </Link>
                        </div>
                      )}
                    </div>
                  </div>
                </Col>
              </Row>
            </Carousel.Item>
          ))}
        </Carousel>
        <div className="position-absolute verticle-text-wrapper verticle-text-home d-none d-lg-block">
          <div id="verticle-text" className=" text-white verticle-text scroll_down_text">
            <p className="d-flex align-items-center justify-content-center">
              Scroll down
              <span className="my-lg-2">
                <img
                  src={downArrow}
                  alt="down-arrow"
                  className="px-2 rotate-arrow scroll_down_arrow"
                />
              </span>
            </p>
          </div>
        </div>
        <div className="bg-whitesmoke py-5">
          <Container>
            <Observer onChange={this.animateWhoWeAre} triggerOnce>
              <Row>
                <Col xs={12} md={6} className="d-flex align-items-start justify-content-center">
                  <div>
                    <h2 className="text-appcolor text-md-left text-uppercase opacity-0 animate-who-we-up">
                      <span className="text-extra-bold d-block ">{sectionOne.title1}</span>{' '}
                      <span className="text-regular">{sectionOne.title2}</span>
                    </h2>
                    <LightBluekey width="100px" />
                    <p className="animate-who-we-desc-up">{sectionOne.description}</p>
                  </div>
                </Col>
                <Col xs={12} md={6}>
                  <div className="square mx-auto my-md-4 my-lg-0">
                    <div className="animate-who-we-right">
                      {/* <img src={WhoWeAre} className="img-fluid" alt="who we are" /> */}
                      <Img className="img-fluid" fluid={sectionOne.image.childImageSharp.fluid} />
                    </div>
                    <div className="discover-more-btn bg-orange animate-who-we-upx">
                      <Link to="/our-mission/" className="text-white">
                        <Card.Body className="py-3 px-auto shadow employee-card-body">
                          <div className="d-flex align-items-center justify-content-center">
                            <p className="m-0 text-white text-small text-bold text-uppercase">
                              Discover More{' '}
                              <ReadMore
                                className="text-white arrow-translate"
                                height="14px"
                                width="45px"
                              />
                            </p>
                          </div>
                        </Card.Body>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Observer>
          </Container>
        </div>

        <div className="bg-appcolor pt-5">
          <Container>
            <Observer onChange={this.animateDifferent} triggerOnce>
              <Row>
                <Col xs={12} md={12}>
                  <div className="">
                    <h2 className="text-white text-md-left text-uppercase animate-diff-fade-up opacity-0">
                      <span className="text-extra-bold">{sectionTwo.title1}</span>{' '}
                      <span className="text-regular">{sectionTwo.title2}</span>
                    </h2>
                    <YellowKeySecond width="100px" />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="home-slider">
                    <div className="bg-white">
                      <div className="slider-wrapper">
                        <div className="slider-img animate-diff-fade-left">
                          {/* <img src={howWeAreDiff} className="img-fluid" alt="who we are" /> */}
                          <Img
                            className="img-fluid"
                            fluid={sectionTwo.image.childImageSharp.fluid}
                          />
                        </div>
                        <div className="carousel-wrapper animate-diff-fade-desc-up">
                          <Carousel controls={false} interval={10000}>
                            {sectionTwo.carousel.map(section => (
                              <Carousel.Item>
                                <div>
                                  <p className="text-appcolor text-regular home_diff_slider">
                                    {section.text}
                                  </p>
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div className="discover-more-btn-two  bg-appcolor-light animate-diff-fade-upx">
                      <Link to="/positioning/" className="text-white">
                        <Card.Body className="py-3 shadow employee-card-body">
                          <div className="d-flex justify-content-center align-items-center">
                            <p className="m-0 text-white text-uppercase text-small text-bold">
                              Discover More{' '}
                              <ReadMore
                                className="text-white arrow-translate"
                                height="14px"
                                width="45px"
                              />
                            </p>
                          </div>
                        </Card.Body>
                      </Link>
                    </div>
                  </div>
                </Col>
              </Row>
            </Observer>
          </Container>
        </div>

        <div className="bg-white pt-5">
          <Container>
            <Observer onChange={this.animateOurImpact} triggerOnce>
              <Row className="animate-impact-fade-up opacity-0">
                <Col xs={12} md={12} className="d-flex align-items-center justify-content-start">
                  <div className="py-lg-5">
                    <h2 className="text-appcolor text-center text-md-left text-uppercase animate-impact-fade-up">
                      <span className="text-extra-bold">{sectionThree.title1}</span>{' '}
                      <span className="text-regular">{sectionThree.title2}</span>
                    </h2>
                    <YellowKey width="100px" />
                  </div>
                </Col>
                <Col xs={12} md={12}>
                  <div className="pl-md-5 client-testimonials animate-impact-fade-desc-up">
                    <h5 className="text-bold text-appcolor mb-lg-0 text-capitalize">
                      {sectionThree.heading}
                    </h5>
                  </div>
                  <div className="home-slider slider-two animate-impact-fade-desc-up">
                    <div className="bg-appcolor">
                      <div className="slider-wrapper">
                        <div className="slider-img animate-impact-fade-left">
                          <img
                            src={sectionThree.image.publicURL}
                            className="img-fluid"
                            alt="who we are"
                          />
                        </div>
                        <div className="carousel-wrapper">
                          <Carousel
                            controls={false}
                            indicators={false}
                            id="quote-carousel"
                            activeIndex={index}
                            direction={direction}
                            autoPlay
                          >
                            {sectionThree.carousel.map(content => (
                              <Carousel.Item className="bg-appcolor">
                                <Row>
                                  <Col md={{ span: 8 }}>
                                    <div className="d-flex flex-row justify-content-start align-items-center">
                                      <div className="client-logo">
                                        <div style={{ height: '120px', width: '120px' }}>
                                          <Img
                                            className="img-fluid"
                                            fluid={content.image.childImageSharp.fluid}
                                          />
                                        </div>
                                      </div>

                                      <div className="pl-4 text-bold">
                                        <p className="mb-0 text-lightblue">{content.post}</p>
                                        <h4 className="text-lightblue">{content.title}</h4>
                                      </div>
                                    </div>
                                  </Col>
                                </Row>
                                <div>
                                  <p className="text-white text-bold mt-5 mb-4">
                                    {content.description}
                                  </p>
                                </div>
                              </Carousel.Item>
                            ))}
                          </Carousel>
                        </div>
                      </div>
                    </div>
                    <div className="discover-more-btn shadow">
                      <Row noGutters>
                        <Col>
                          <div className="py-4 employee-card-body bg-orange animate-impact-fade-left">
                            <div className="d-flex justify-content-center">
                              <p className="m-0 text-white text-bold">
                                {`${index + 1}`.slice(-2)} / {carouselItemCount}
                              </p>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="bg-white animate-impact-fade-upx">
                            <div
                              className="py-4 bg-whitesmoke cursor-pointer employee-card-body"
                              onClick={() => this.toggleCarousel('prev')}
                            >
                              <div className="d-flex justify-content-center">
                                <div className="m-0 text-bold">
                                  <div className="slider-arrows">
                                    <a className="left carousel-control">
                                      <img src={LeftArrow} alt="" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                        <Col>
                          <div className="bg-white animate-impact-fade-right">
                            <div
                              style={{ borderLeft: '2px solid rgba(10,65,105,.2)' }}
                              className="py-4 bg-whitesmoke cursor-pointer employee-card-body"
                              onClick={() => this.toggleCarousel('next')}
                            >
                              <div className="d-flex justify-content-center">
                                <div className="m-0 text-bold">
                                  <div className="slider-arrows">
                                    <a className="right carousel-control">
                                      <img src={RightArrow} alt="" className="img-fluid" />
                                    </a>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Row>
                    </div>
                  </div>
                </Col>
              </Row>
            </Observer>
          </Container>
        </div>

        <div className="bg-white wrapper">
          <Container fluid>
            <Row className="justify-content-lg-around p-lg-5">
              {sectionFour.cards.map(card => (
                <Col xs={12} lg={5} className="p-2">
                  <div className="bg_card">
                    <div className="card_home position-relative">
                      <Img className="img-fluid" fluid={card.image.childImageSharp.fluid} />
                      <div className="overlay card-content p-3 p-lg-5">
                        <div className="">
                          <h4 className="text-white mb-3">{card.title}</h4>
                          <p className="text-white my-2 text-left">{card.description}</p>
                        </div>
                      </div>
                    </div>
                    <div
                      className={`learn_more h-auto mx-auto w-75 d-lg-block d-none ${card.btnColor}`}
                      style={{ transform: 'translate(0%, -50%)' }}
                    >
                      <div className="d-flex justify-content-center mx-auto">
                        <Link
                          to={`/${card.pageLink}/`}
                          className="d-block p-lg-4 p-3 text-white text-bold text-uppercase text-small"
                        >
                          Discover More{' '}
                          <ReadMore
                            className="text-white arrow-translate"
                            height="14px"
                            width="45px"
                          />
                        </Link>
                      </div>
                    </div>
                  </div>

                  <div
                    className={`learn_more h-auto mx-auto w-100 d-lg-none d-block ${card.btnColor}`}
                  >
                    <div className="d-flex justify-content-center mx-auto">
                      <Link
                        to={`/${card.pageLink}/`}
                        className="d-block p-4 text-white text-small text-uppercase text-bold"
                      >
                        Discover More{' '}
                        <ReadMore
                          className="text-white arrow-translate"
                          height="14px"
                          width="45px"
                        />
                      </Link>
                    </div>
                  </div>
                </Col>
              ))}
            </Row>
          </Container>
        </div>

        <div className="bg-whitesmoke wrapper">
          <Container>
            <Row className="justify-content-center">
              <Col xs={12} lg={5} className="d-flex flex-column justify-content-between px-2">
                <div className="values_card_home mt-3">
                  <h2 className="text-appcolor text-left text-uppercase animate-culture-fade-down">
                    <span className="text-extra-bold d-block">our</span>{' '}
                    <span className="text-regular">culture</span>
                  </h2>
                  <div className="pb-3">
                    <LightBluekey width="100px" />
                  </div>
                </div>
                <div>
                  <div className="brick">
                    <div className="values_card_home culture position-relative animate-culture-fade-left">
                      <Img className="img-fluid" fluid={one.image.childImageSharp.fluid} />
                      <div className="overlay d-flex flex-column justify-content-center culture-overlay">
                        <h5 className="text-white mb-0 align-self-start culture-title px-lg-5 px-3 text-left">
                          {one.title}
                        </h5>
                        <div className="align-self-center value_text flex-column">
                          <div className="my-2 text-left align-self-center" />
                          <div className="text-white my-2 text-left px-lg-5 px-3">
                            <p className="home_card font-italic">{one.description}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="learn_more brick bg-orange my-3 mb-lg-0 values_card_home h-auto d-none d-lg-block">
                    <div className="d-flex justify-content-center w-100 animate-culture-fade-up">
                      <Link
                        to="/what-is-the-bluePrint-culture-like/"
                        className="d-block p-4 text-small text-uppercase text-white text-bold"
                      >
                        Discover More{' '}
                        <ReadMore
                          className="text-white arrow-translate"
                          height="14px"
                          width="45px"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </Col>
              <Col xs={12} lg={5} className="px-2 d-flex flex-column justify-content-between">
                <div className="brick my-3">
                  <div className="values_card_home culture position-relative animate-culture-fade-down">
                    {/* <img src={Recognition} alt="recognition and reward" /> */}
                    <Img className="img-fluid" fluid={two.image.childImageSharp.fluid} />
                    <div className="overlay d-flex flex-column justify-content-center culture-overlay">
                      <h5 className="text-white mb-0 align-self-start px-lg-5 px-3 culture-title">
                        {two.title}
                      </h5>
                      <div className="align-self-center value_text flex-column">
                        <div className="my-2 text-left align-self-center" />
                        <div className="text-white my-2 text-left px-lg-5 px-3">
                          <p className="font-italic">{two.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="brick">
                  <div className="values_card_home culture position-relative animate-culture-fade-right">
                    {/* <img src={MakingAnImpact} alt="making an impact" /> */}
                    <Img className="img-fluid" fluid={three.image.childImageSharp.fluid} />
                    <div className="overlay d-flex flex-column justify-content-center culture-overlay">
                      <h5 className="text-white mb-0 align-self-start px-lg-5 px-3 culture-title text-left">
                        {three.title}
                      </h5>
                      <div className="align-self-center value_text flex-column">
                        <div className="my-2 text-left align-self-center" />
                        <div className="text-white my-2 text-left px-lg-5 px-3">
                          <p className="font-italic">{three.description}</p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="learn_more brick bg-orange my-3 mb-lg-0 values_card_home h-auto d-lg-none d-block">
                  <div className="d-flex justify-content-center w-100 animate-culture-fade-up">
                    <Link
                      to="/what-is-the-bluePrint-culture-like/"
                      className="d-block p-4 text-white text-uppercase text-small text-bold"
                    >
                      Discover More{' '}
                      <ReadMore className="text-white arrow-translate" height="14px" width="45px" />
                    </Link>
                  </div>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
      </Layout>
    );
  }
}
export const query = graphql`
  query {
    markdownRemark(frontmatter: { page: { eq: "home" } }) {
      frontmatter {
        seo
        carousel {
          title
          class
          columnClass
          buttonText
          buttonLink
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          positionClass
          spacingClass
          description
        }
        sectionOne {
          title1
          title2
          description
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
        }
        sectionTwo {
          title1
          title2
          image {
            childImageSharp {
              fluid(maxWidth: 512, quality: 100) {
                ...GatsbyImageSharpFluid_withWebp
              }
            }
          }
          carousel {
            text
          }
        }
        sectionThree {
          title1
          title2
          heading
          image {
            publicURL
          }
          carousel {
            title
            post
            image {
              childImageSharp {
                fluid(maxWidth: 1024, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
            description
          }
        }
        sectionFour {
          cards {
            title
            description
            class
            btnColor
            pageLink
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
        sectionFive {
          title1
          title2
          one {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          two {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
          three {
            title
            description
            image {
              childImageSharp {
                fluid(maxWidth: 512, quality: 100) {
                  ...GatsbyImageSharpFluid_withWebp
                }
              }
            }
          }
        }
      }
    }
  }
`;
